<template>
    <div :style="nuvolosIframe">
        <div class="d-flex flex-column justify-center align-center" style="height: 100%; width: 100%" v-if="createdDeployments.length === 0">
            <div class="d-flex flex-column align-center justify-center text-center">
                <div v-if="launchingApp">
                    <p>Preparing the application for launch</p>
                    <v-progress-linear indeterminate color="primary" rounded height="6" class="my-3"></v-progress-linear>
                </div>
                <v-stepper :value="1" v-if="launchingApp">
                    <v-stepper-header>
                        <v-stepper-step step="1">Allocating</v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="2">Configuring</v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="3">Booting</v-stepper-step>
                    </v-stepper-header>
                </v-stepper>
                <div v-if="!launchingApp && appIssue">
                    <template v-if="appIssue === 'resource'">
                        This application was stopped due to inactivity or exceeding available resources, please try
                        <v-btn @click="restartApp" text color="primary" class="text-lowercase" small>
                            <v-icon small class="mr-1">power_settings_new</v-icon>
                            restarting
                        </v-btn>
                        the application.
                    </template>
                    <template v-if="appIssue === 'comms'">
                        This application has become inaccessible. You might need to
                        <v-btn @click="restartApp" text color="primary" class="text-lowercase" small>
                            <v-icon small class="mr-1">power_settings_new</v-icon>
                            restart
                        </v-btn>
                        the application.
                    </template>
                </div>
                <timeout-handler :timeout="5000" v-if="!launchingApp && !appIssue">
                    There might be a temporary issue with launching this application, please try
                    <v-btn @click="restartApp" text color="primary" class="text-lowercase" small>
                        <v-icon small class="mr-1">power_settings_new</v-icon>
                        restarting
                    </v-btn>
                    the application.
                </timeout-handler>
            </div>
        </div>
        <div v-if="openedDeployments.length" style="height: 100%; width: 100%">
            <AppIframe
                v-for="item in openedDeployments"
                :key="item.session_id"
                :deployment="item"
                :isActive="$route.name === 'app-open' && item.session_id === $route.params.sessionId"
                :appType="item.app_type" />
        </div>
        <v-dialog v-model="showCredentialModal" max-width="400">
            <v-card>
                <v-card-title>University Login Required</v-card-title>
                <v-card-text>
                    Please provide your
                    <span class="font-weight-bold">university credentials</span>
                    to establish a connection to the licence server.
                    <v-alert type="info" class="my-2">
                        Irrespective of how you log into Nuvolos, please provide your university credentials here. Wrong credentials will result in the licensed
                        application not starting.
                    </v-alert>
                    <v-alert type="error" v-if="enteredEmailAndNotUsername">
                        It appears you entered your e-mail address instead of your login name. Please check your login name
                        <a href="https://my-account.unige.ch/my-account/web/home" target="_blank">here</a>
                        .
                    </v-alert>
                    <v-form @submit="launchAppIfNeeded($route.params.aid)" onSubmit="return false;" class="text-center">
                        <v-text-field label="Username" v-model="appUsername" outlined></v-text-field>
                        <v-text-field v-model="appPwd" label="University Password" type="password" outlined></v-text-field>
                        <v-btn class="mt-2 font-weight-bold" color="primary" type="submit" width="100%" block :disabled="enteredEmailAndNotUsername">
                            Launch App
                        </v-btn>
                        <v-btn text color="primary" @click="goToOverview">Cancel</v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import Shepherd from 'shepherd.js'
import { mapState } from 'vuex'
import { dropboxEnums } from '@/mixins/dropbox'
import { enumsData } from '@/mixins/enums'
import { intercomMethods } from '@/mixins/intercom'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import { sortBy } from 'lodash'

import AppIframe from '@/modules/application/components/AppIframe'
const TimeoutHandler = () => import('@/components/TimeoutHandler')

export default {
    components: { AppIframe, TimeoutHandler },
    mixins: [appTypeAndImageLink, dropboxEnums, enumsData, intercomMethods],
    data() {
        return {
            activeSession: '',
            appUsername: '',
            appPwd: '',
            instanceTypes: [],
            lastScaled: 0,
            lastSessionId: '',
            launchingApp: false,
            openedSessions: [],
            showCredentialModal: false,
            appHelpTour: null,
            showHelp: false,
            appIssue: ''
        }
    },
    computed: {
        createdDeploymentsAll() {
            return this.deploymentStatus.filter(d => {
                return d.iid.toString() === this.$route.params.iid.toString()
            })
        },
        openedDeployments() {
            return sortBy(
                this.createdDeploymentsAll.filter(d => {
                    return this.openedSessions.includes(d.session_id.toString())
                }),
                'creation_timestamp'
            )
        },
        isScalingEnabled() {
            if (this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE) {
                return this.isSpaceAdmin && this.hpcCanBeEnabled && this.isMasterInstance
            } else {
                return this.isSpaceAdmin && this.hpcCanBeEnabled
            }
        },
        createdDeployments() {
            return sortBy(
                this.createdDeploymentsAll
                    .filter(d => {
                        return d.aid.toString() === (this.$route.params.aid || '').toString()
                    })
                    .filter(d => {
                        if (!this.$route.params.sessionId) {
                            return true
                        } else {
                            return this.$route.params.sessionId === d.session_id
                        }
                    })
                    .filter(d => {
                        return !this.$route.query.grading || d.shared !== 'true'
                    })
                    .filter(d => {
                        return !this.$route.query.handback_bid || this.$route.query.handback_bid.toString() === (d.mount_handback_for_bid || '').toString()
                    })
                    .filter(d => {
                        return (
                            !this.$route.query.handback_hid ||
                            this.$route.query.handback_hid.toString() === (d.mount_handback_to_files_for_hid || '').toString()
                        )
                    }),
                'creation_timestamp'
            )
        },
        runningDeployments() {
            return this.createdDeployments.filter(d => {
                return parseInt(d.available_replicas, 10) > 0
            })
        },
        enteredEmailAndNotUsername() {
            return this.appUsername && this.appUsername.endsWith('unige.ch')
        },
        nuvolosIframe() {
            if (this.$route.name === 'app-open') {
                return { 'z-index': 1, height: '100%', width: '100%' }
            } else {
                return { 'z-index': -1, opacity: 0, width: '100%', position: 'fixed', top: 0, left: 65 }
            }
        },
        canScaleGlobal() {
            if (this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE) {
                return this.isSpaceAdmin && this.isMasterInstance && this.currentSpaceData?.beegfs_sync
            } else {
                return this.currentSpaceData?.beegfs_sync
            }
        },
        nodePool() {
            if (this.$route.query.scale && this.$route.query.scale !== '0' && this.$route.query.scale !== '1') {
                return this.$route.query.scale
            } else {
                return ''
            }
        },
        launchHelpTour() {
            return this.showHelp && this.createdDeployments.length && !this.$route.query.grading && !this.$route.query.handback_bid
        },
        ...mapState('appStore', ['deploymentStatus']),
        ...mapState(['userMetadata', 'userInfo'])
    },
    watch: {
        $route: {
            handler: function (to, from) {
                if (to.params.aid && !to.query.stopAid) {
                    this.$store.dispatch('appStore/updateDeploymentStatus').then(() => {
                        if (this.$route.params.sessionId === 'new') {
                            this.launchAppIfNeeded(to.params.aid)
                            return
                        }
                        if (!this.$route.params.sessionId) {
                            if (this.createdDeployments.length) {
                                this.$router.replace({
                                    params: { ...this.$route.params, sessionId: this.createdDeployments[0].session_id },
                                    query: { ...this.$route.query }
                                })
                            } else {
                                this.$router.replace({ params: { ...this.$route.params, sessionId: 'new' }, query: { ...this.$route.query } })
                            }
                        } else {
                            if (!this.createdDeployments.length && this.$route.params.sessionId !== this.lastSessionId) {
                                this.$router.replace({ params: { ...this.$route.params, sessionId: null }, query: { ...this.$route.query } })
                            }
                        }
                    })
                }
            },
            immediate: true
        },
        runningDeployments: {
            handler: function (to, from) {
                if (!to.length) {
                    if (
                        from &&
                        from.length &&
                        this.$route.name === 'app-open' &&
                        from[0].session_id.toString() === this.$route.params.sessionId.toString() &&
                        Date.now() - this.lastScaled > 10000
                    ) {
                        if (this.createdDeployments && !this.createdDeployments.length) {
                            this.appIssue = 'resource'
                        } else {
                            this.appIssue = 'comms'
                        }
                    }
                }
            },
            immediate: true
        },
        createdDeployments: {
            handler: function (to) {
                if (to.length) {
                    this.activeSession = to[0].session_id
                    this.openedSessions.push(this.activeSession)
                }
            },
            immediate: true
        },
        nodePool: {
            handler: function (to) {
                if (to) {
                    this.lastScaled = Date.now()
                }
            },
            immediate: true
        },
        launchHelpTour(to) {
            if (to) {
                const self = this
                const helpText =
                    `You have recently restarted this application. If you are experiencing technical issues, please refer to our documentation below, clear your user application settings or ask for technical support:` +
                    `<br><br><ul style="padding-left: 20px;">
                            <li><a
                                href="https://docs.nuvolos.cloud/features/snapshots/restore-a-snapshot#partially-restore-a-snapshot"
                                target="_blank"
                                style="text-decoration: none"
                                >Restore application libraries</a
                            ></li>
                            </ul>`
                this.appHelpTour.removeStep('app-help')
                this.appHelpTour.addStep({
                    title: 'Having technical issues?',
                    text: helpText,
                    cancelIcon: {
                        enabled: true
                    },
                    attachTo: {
                        element: '.shepherd-app-sidebar',
                        on: 'right'
                    },
                    buttons: [
                        {
                            action() {
                                self.showNewMessageIntercom('Hi Nuvolos Team, \nI would like to ask your help regarding the following:\n', self.userInfo)
                                return this.complete()
                            },
                            classes: 'shepherd-button-primary',
                            text: 'Ask for technical support'
                        },
                        {
                            action() {
                                this.complete()
                                return self.$router.push({ name: 'snapshot-applications', query: { resetAid: self.$route.params.aid } })
                            },
                            text: `Clear application settings`
                        }
                    ],
                    id: 'app-help'
                })
                this.appHelpTour.start()
            }
        }
    },
    methods: {
        launchAppIfNeeded(aid) {
            this.launchingApp = true
            if (
                this.userMetadata?.last_app_start?.aid?.toString() === this.$route.params.aid.toString() &&
                Date.now() - this.userMetadata.last_app_start.start_time < 600000 &&
                !this.nodePool
            ) {
                this.showHelp = true
            }
            this.$store
                .dispatch('appStore/fetchAllUserApps', { iid: this.$route.params.iid, startFirstApp: false })
                .then(() => {
                    if (!this.createdDeployments.length) {
                        this.showCredentialModal = false
                        // need to start the application - let the user know
                        this.$store
                            .dispatch('appStore/startApp', {
                                aid: aid.toString(),
                                appUsername: this.appUsername,
                                appPwd: this.appPwd
                            })
                            .then(response => {
                                if (response instanceof Error) {
                                    const code = response.response.data.code
                                    if (code === 'vpn_credentials_missing') {
                                        this.showCredentialModal = true
                                    } else if (code === 'resource_pool_ended') {
                                        this.$store.dispatch('showGlobalDialog', {
                                            dialogText:
                                                'We were not able to start this application - its resource pool has expired. Please ask your resource pool manager to extend your plan.',
                                            dialogTitle: 'Application start failed',
                                            dialogAction: ['returnToWelcomePage']
                                        })
                                    } else {
                                        this.$store.dispatch('showGlobalDialog', {
                                            dialogText:
                                                'We were not able to start this application - this might be a temporary issue, if it persists please reach out to support@nuvolos.cloud',
                                            dialogTitle: 'Application start failed',
                                            dialogAction: ['returnToWelcomePage', 'restart-application']
                                        })
                                    }
                                    return
                                }

                                this.lastSessionId = response

                                this.appUsername = ''
                                this.appPwd = ''
                                this.$store.dispatch('showSnackBar', {
                                    snackBarText: 'Starting application...',
                                    snackBarTimeout: 10000,
                                    snackBarIcon: 'info'
                                })
                                this.$router.replace({
                                    params: { ...this.$route.params, sessionId: response },
                                    query: { ...this.$route.query }
                                })
                                if (!this.nodePool) {
                                    this.$store.commit('setUserMetadata', { last_app_start: { ...this.$route.params, start_time: Date.now() } })
                                }
                            })
                            .catch(e => {
                                console.log(e)
                            })
                            .finally(() => {
                                // allow sime time to app_status to finish
                                window.setTimeout(() => {
                                    this.launchingApp = false
                                }, 3000)
                                window.setTimeout(() => {
                                    this.showHelp = false
                                }, 5000)
                            })
                    }
                })
                .catch(e => {
                    console.log(e)
                    window.setTimeout(() => {
                        this.launchingApp = false
                    }, 3000)
                })
        },
        restartApp() {
            location.reload()
        },
        goToOverview() {
            this.$router.push({
                name: 'snapshot-overview',
                params: { oid: this.$route.params.oid, sid: this.$route.params.sid, iid: this.$route.params.iid, snid: this.$route.params.snid }
            })
            this.showCredentialModal = false
        }
    },
    mounted() {
        this.checkDropboxStatus()
        this.appHelpTour = new Shepherd.Tour({
            defaultStepOptions: {
                cancelIcon: {
                    enabled: true
                },
                classes: 'secondary--text',
                scrollTo: { behavior: 'smooth' }
            }
        })
        this.appHelpTour.addStep({ id: 'app-help' })
    }
}
</script>

<style scoped>
.nuvolos-iframe {
    height: 100vh;
    width: '100%';
    overflow-x: 'hidden';
    overflow-y: 'hidden';
}
</style>
